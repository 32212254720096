import React from "react"
import { Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const { headshot } = useStaticQuery(graphql`
    query {
      headshot: file(relativePath: { eq: "greenshirt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      <h1 style={{ textAlign: 'center' }}>Hello World</h1>
      <div className="index__headshot-container" style={{ width: '30%', display: 'block', margin: 'auto' }}>
        <Img fluid={headshot.childImageSharp.fluid} />
      </div>
      <div style={{ width: '40%', display: 'block', margin: 'auto' }}>
        <p>
          I got my start in middle school at a program called Real Programming 4 Kids, developing a Donkey Kong clone in Visual Basic .NET.
          These days, I'm less interested in game dev and more interested in solving problems and making life more efficient.
          I graduated in 2014 from University of Waterloo's Bachelor of Computer Science program with Co-op.
        </p>
        <p>
          My text editor of choice is Sublime Text and my version control platform of choice is GitLab.
        </p>
        <p>
          I couldn't bring myself to make this site with Wix. It's just the sort of person I am.
          The technology I built the site with, Gatsby, is a powerful static site generator that uses GraphQL to gather and marshal various assets at build time.
          It also has plugins for deployment to S3, which along with Cloudfront makes it easy to serve this site without server code.
        </p>
        <p>
          <a href="mailto:chris.va.rao@gmail.com">chris.va.rao@gmail.com</a>
        </p>
      </div>
    </Layout>
  );
};

export default IndexPage
